import {
  Dispatch,
  FC,
  KeyboardEventHandler,
  SetStateAction,
  useState,
} from 'react';
import { Flex } from '@chakra-ui/react';

interface Props {
  setTrackIsActive: Dispatch<SetStateAction<boolean>>;
  setActiveItem: Dispatch<SetStateAction<number>>;
  activeItem: number;
  constraint: number;
  itemWidth: number;
  positions: number[];
  index: number;
  gap: number;
}

const CarouselItem: FC<Props> = ({
  setTrackIsActive,
  setActiveItem,
  activeItem,
  constraint,
  itemWidth,
  positions,
  children,
  index,
  gap,
}) => {
  const [userDidTab, setUserDidTab] = useState(false);

  const handleFocus = () => {
    setTrackIsActive(true);
  };

  const handleBlur = () => {
    if (userDidTab && index + 1 === positions.length) {
      setTrackIsActive(false);
    }
    setUserDidTab(false);
  };

  const handleKeyUp: KeyboardEventHandler<HTMLDivElement> = ({ key }) => {
    if (key === 'Tab' && !(activeItem === positions.length - constraint)) {
      setActiveItem(index);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = ({ key }) => {
    if (key === 'Tab') {
      setUserDidTab(true);
    }
  };

  return (
    <Flex
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      w={`${itemWidth}px`}
      _notLast={{
        mr: `${gap}px`,
      }}
      py="4px"
    >
      {children}
    </Flex>
  );
};
export default CarouselItem;
