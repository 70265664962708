import { Box, Text, Flex, Heading } from '@chakra-ui/react';
import { Clock, MapPin, Laptop } from '@phosphor-icons/react';
import { FC } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from '../lib/hooks.context';
import {
  isMultipleDays,
  formatDate,
  formatHour,
  createDate,
} from '../lib/utils.date';
import { formatLocation } from '../lib/utils';
import { Event, MhyChakraProps } from '../types';
import BasicTeaser, { TeaserHeader } from './BasicTeaser';
import ButtonLink from './ButtonLink';

// TODO: add image, phone, email and format texts

const TextLine: FC<Omit<MhyChakraProps, 'as'>> = (props) => (
  <Flex as={Text} {...props} gridGap="2" alignItems="flex-start" />
);

const EventTeaser = ({
  url,
  title,
  site,
  location,
  start_time,
  end_time,
  id,
  price,
  as: asElem,
  is_webinar,
  ...rest
}: Event & MhyChakraProps) => {
  const t = useTranslation();
  const start = start_time ? createDate(start_time) : undefined;
  const end = end_time ? createDate(end_time) : undefined;
  const isMultiDayEvent = end_time ? isMultipleDays(start, end) : false;
  const displayedAsListItem = asElem && asElem === 'li';
  const { locale } = useRouter();

  const content = (
    <BasicTeaser
      width="100%"
      maxWidth="500px"
      as="article"
      hasTeaserHeader={!!site}
      {...(displayedAsListItem ? null : { as: asElem || 'article', ...rest })}
    >
      {site && (
        <TeaserHeader>
          <Text as="time" textStyle="eventDate">
            {formatDate(start, locale)}
            {isMultiDayEvent ? ` - ${formatDate(end, locale)}` : ''}
          </Text>
          <Text as="span" textStyle="sm">
            {site === 'Valtakunnallinen' ? t('main_site') : site}
          </Text>
        </TeaserHeader>
      )}
      <Heading variant="h5" as="h2">
        {title}
      </Heading>
      {location || is_webinar || start || end ? (
        <Flex gridGap={2} flexDirection="column">
          {location || is_webinar ? (
            <TextLine textStyle="sm">
              <Text as="span" color="secondaryColor">
                {location ? <MapPin size="1.4em" /> : <Laptop size="1.4em" />}
              </Text>
              {location ? formatLocation(location) : t('webinar')}
            </TextLine>
          ) : null}
          {start || end ? (
            <TextLine textStyle="sm">
              <Text as="span" color="secondaryColor">
                <Clock size="1.4em" />
              </Text>
              {(start && formatHour(start) !== '00.00') ||
              (formatHour(start) === '00.00' &&
                end &&
                formatHour(end) !== '00.00')
                ? formatHour(start)
                : t(
                    isMultiDayEvent ? 'event_multiple_days' : 'event_whole_day',
                  )}
              {end && formatHour(end) !== '00.00'
                ? ` - ${formatHour(end)}`
                : ''}
            </TextLine>
          ) : null}
        </Flex>
      ) : null}
      {url && (
        <ButtonLink
          link={{ url, title: t('read_more'), target: '_self' }}
          variant="outlineGreen"
        />
      )}
    </BasicTeaser>
  );

  // Normally should be displayed as a list, but in certain cases it's not possible (Carousel). In that case this makes styling a lot easier. See EventTeasers
  return displayedAsListItem ? (
    <Box {...rest} as="li">
      {content}
    </Box>
  ) : (
    content
  );
};

export default EventTeaser;
