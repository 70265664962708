import { useCallback, useEffect, useState, useMemo, FC } from 'react';

import { useMediaQuery, useTheme } from '@chakra-ui/react';
import CarouselSlider from './CarouselSlider';
import CarouselTrack from './CarouselTrack';
import CarouselItem from './CarouselItem';

interface Props {
  carouselType: 'teaser' | 'event';
}

const gap = 20;

const Carousel: FC<Props> = ({ children, carouselType }) => {
  const [trackIsActive, setTrackIsActive] = useState(false);
  const [multiplier, setMultiplier] = useState(0.35);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [activeItem, setActiveItem] = useState(0);
  const [constraint, setConstraint] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);

  const initSliderWidth = useCallback((width) => setSliderWidth(width), []);

  const slideCount = carouselType === 'event' ? 4 : 3;

  const positions = useMemo(
    () =>
      Array.isArray(children)
        ? children.map((_, index) => -Math.abs((itemWidth + gap) * index))
        : [],
    [children, itemWidth],
  );

  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

  useEffect(() => {
    if (isGreaterThanMd) {
      setItemWidth(sliderWidth / slideCount - gap);
      setMultiplier(0.5);
      setConstraint(slideCount);
    }
  }, [isGreaterThanMd, sliderWidth, slideCount]);

  return (
    <CarouselSlider
      setTrackIsActive={setTrackIsActive}
      initSliderWidth={initSliderWidth}
      setActiveItem={setActiveItem}
      activeItem={activeItem}
      constraint={constraint}
      positions={positions}
      gap={gap}
      carouselType={carouselType}
    >
      <CarouselTrack
        setTrackIsActive={setTrackIsActive}
        trackIsActive={trackIsActive}
        setActiveItem={setActiveItem}
        activeItem={activeItem}
        constraint={constraint}
        multiplier={multiplier}
        itemWidth={itemWidth}
        positions={positions}
      >
        {Array.isArray(children) &&
          children.map((child, index) => (
            <CarouselItem
              setTrackIsActive={setTrackIsActive}
              setActiveItem={setActiveItem}
              activeItem={activeItem}
              constraint={constraint}
              itemWidth={itemWidth}
              positions={positions}
              gap={gap}
              index={index}
              key={index}
            >
              {child}
            </CarouselItem>
          ))}
      </CarouselTrack>
    </CarouselSlider>
  );
};
export default Carousel;
