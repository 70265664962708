import { FC } from 'react';
import { Box, List, SimpleGrid } from '@chakra-ui/react';
import { MhyChakraProps } from '../types';
import OrderedList from './OrderedList';
import { safariOnlyCss } from '../styles/theme';

interface Props extends MhyChakraProps {
  gridSize?: string;
  isOrderedContent?: boolean;
  colCount?: 3 | 4 | '3' | '4';
  itemMinWidth?: string;
}

const simpleGridStyles = {
  display: ['flex', null, 'grid'],
  gridAutoFlow: 'column',
  px: [0],
  _before: {
    content: ['""', null, 'none'],
    minWidth: ['calc(var(--mhy-space-5))', null, 0],
  },
  _after: {
    content: ['""', null, 'none'],
    minWidth: ['calc(var(--mhy-space-5))', null, 0],
  },
  margin: ['0 calc(-1 * var(--mhy-space-5))', null, 0],
  sx: {
    scrollSnapType: 'x proximity',
    scrollBarWidth: 'none',
    '> li': {
      minWidth: [
        'calc(70% - var(--mhy-space-5) * 2.5)',
        'calc(60% - var(--mhy-space-5) * 2.5)',
        0,
      ],
      ...safariOnlyCss({ mr: 5 }, 'lg', true),
    },
  },
};

const getSimpleGridStyles = (colCount = 3, minWidth = '250px') => ({
  ...simpleGridStyles,
  gridTemplateColumns: [
    null,
    null,
    `repeat(${colCount}, ${minWidth ? `minmax(${minWidth}, 1fr)` : '1fr'})`,
  ],
});

/*
 Lists things, so it's a list. Give it list items.
*/
const ArticleGridWrapper: FC<Props> = ({
  children,
  gridSize = 'lgGrid',
  colCount,
  itemMinWidth,
  isOrderedContent = false,
  ...rest
}) => (
  <Box {...rest} overflowX="hidden">
    <SimpleGrid
      layerStyle={[gridSize]}
      overflowX="auto"
      as={isOrderedContent ? OrderedList : List}
      {...getSimpleGridStyles(
        colCount && Number.parseInt(`${colCount}`, 10),
        itemMinWidth,
      )}
    >
      {children}
    </SimpleGrid>
  </Box>
);
export default ArticleGridWrapper;
