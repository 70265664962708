import { Dispatch, FC, SetStateAction, useLayoutEffect } from 'react';
import { Button, Flex, Box } from '@chakra-ui/react';
import { ArrowLeft, ArrowRight } from '@phosphor-icons/react';
import { useBoundingRect } from '../../lib/hooks';

interface Props {
  setTrackIsActive: Dispatch<SetStateAction<boolean>>;
  initSliderWidth: (arg: number) => void;
  setActiveItem: Dispatch<SetStateAction<number>>;
  activeItem: number;
  constraint: number;
  positions: number[];
  gap: number;
  carouselType: string;
}

const CarouselSlider: FC<Props> = ({
  setTrackIsActive,
  initSliderWidth,
  setActiveItem,
  activeItem,
  constraint,
  positions,
  children,
  gap,
  carouselType,
}) => {
  const [ref, dimensions] = useBoundingRect();
  const width = dimensions?.width || 0;
  useLayoutEffect(
    () => initSliderWidth(Math.round(width)),
    [width, initSliderWidth],
  );

  const handleFocus = () => {
    setTrackIsActive(true);
  };

  const handleDecrementClick = () => {
    setTrackIsActive(true);
    if (activeItem) {
      setActiveItem((prev) => prev - 1);
    }
  };
  const hasMoreItemsToShow = activeItem !== positions.length - constraint;

  const handleIncrementClick = () => {
    setTrackIsActive(true);
    if (hasMoreItemsToShow) {
      setActiveItem((prev) => prev + 1);
    }
  };

  return (
    <>
      <Box
        ref={ref}
        w={{ base: '100%', md: `calc(100% + ${gap}px)` }}
        ml={{ base: 0, md: `-${gap / 2}px` }}
        px={`${gap / 2}px`}
        position="relative"
        _before={{
          bgGradient: 'linear(to-r, base.d400, transparent)',
          position: 'absolute',
          w: `${gap / 2}px`,
          content: "''",
          zIndex: 1,
          h: '100%',
          left: 0,
          top: 0,
        }}
        _after={{
          bgGradient: 'linear(to-l, base.d400, transparent)',
          position: 'absolute',
          w: `${gap / 2}px`,
          content: "''",
          zIndex: 1,
          h: '100%',
          right: 0,
          top: 0,
        }}
      >
        {children}
        <Flex>
          {!activeItem ? null : (
            <Button
              onClick={handleDecrementClick}
              onFocus={handleFocus}
              variant={
                carouselType === 'event'
                  ? 'sliderArrowEvent'
                  : 'sliderArrowArticle'
              }
              zIndex={2}
              position="absolute"
              left="-8px"
              transform="translateY(-50%)"
            >
              <ArrowLeft size="22" />
            </Button>
          )}
          {hasMoreItemsToShow ? (
            <Button
              onClick={handleIncrementClick}
              onFocus={handleFocus}
              variant={
                carouselType === 'event'
                  ? 'sliderArrowEvent'
                  : 'sliderArrowArticle'
              }
              zIndex={2}
              position="absolute"
              right="-8px"
              transform="translateY(-50%)"
            >
              <ArrowRight size="22" />
            </Button>
          ) : null}
        </Flex>
      </Box>
    </>
  );
};

export default CarouselSlider;
