import { Box, ChakraProps, Flex, Grid } from '@chakra-ui/react';
import { FC } from 'react';
import { MhyChakraProps } from '../types';

/*
 * Sort of generic teaser component, which hides complexity into it for ease of use
 *
 * Usage
 * 1)
 * <BasicTeaser hasTeaserHeader={true}>
 *   <TeaserHeader>header content</TeaserHeader>
 *   teaser content
 * </BasicTeaser>
 *
 * OR
 *
 * 2)
 * <BasicTeaser>
 *   teaser content
 * </BasicTeaser>
 */

export const TeaserHeader: FC<ChakraProps> = (props) => (
  <Flex
    as="header"
    justifyContent="space-between"
    alignItems="center"
    gridColumnGap="1"
    px="4"
    py=".375rem"
    background="tableHeading"
    color="backgroundColor"
    borderTopRadius="5"
    sx={{ ' > *': { m: 0 } }}
    {...props}
  />
);

const TeaserContent: FC<ChakraProps> = (props) => (
  <Grid
    px="4"
    pt="3"
    pb="4"
    gridGap={['3', '4', '5']}
    gridTemplateColumns="100%"
    alignItems="flex-start"
    {...props}
  />
);

TeaserHeader.displayName = 'TeaserHeader';

const BasicTeaser: FC<
  Omit<MhyChakraProps, 'variant'> & { hasTeaserHeader?: boolean }
> = ({ children, hasTeaserHeader = false, ...props }) => {
  if (!hasTeaserHeader || !Array.isArray(children)) {
    return (
      <TeaserContent
        borderRadius="8"
        background="secondaryBackgroundColor"
        {...props}
      >
        {children}
      </TeaserContent>
    );
  }

  const [header, ...content] = children;

  return (
    <Box borderRadius="8" background="secondaryBackgroundColor" {...props}>
      {header}
      <TeaserContent>{content}</TeaserContent>
    </Box>
  );
};

export default BasicTeaser;
