import { parse, isValid } from 'date-fns';

const formatTimeUnit = (timeUnit: number): string =>
  `${timeUnit < 10 ? `0${timeUnit}` : timeUnit}`;

export const formatDate = (date?: Date, locale = 'fi', showYear = false) => {
  // eslint-disable-next-line no-nested-ternary
  const localeFi = !showYear ? (locale === 'sv' ? 'sv-FI' : locale) : 'fi';
  const dateFormat: Intl.DateTimeFormatOptions = showYear
    ? { day: 'numeric', month: 'numeric', year: 'numeric' }
    : { day: 'numeric', month: 'numeric' };
  return `${Intl.DateTimeFormat(localeFi, dateFormat).format(date)}`;
};

export const formatHour = (date?: Date) =>
  date &&
  `${formatTimeUnit(date.getHours())}.${formatTimeUnit(date.getMinutes())}`;

export const isMultipleDays = (start?: Date, end?: Date) => {
  if (!start || !end) {
    return false;
  }
  return Math.abs(end.getDate() - start.getDate()) >= 1;
};

export const createDate = (time?: string): Date =>
  time ? new Date(time.replace(/\s/, 'T')) : new Date();

export const parseDate = (
  dateString: string,
  formatString: string,
): Date | null => {
  const parsedDate = parse(dateString, formatString, new Date());
  return isValid(parsedDate) ? parsedDate : null;
};
