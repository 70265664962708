import { FC } from 'react';
import { As, Flex, Grid, Heading } from '@chakra-ui/react';
import { MhyChakraProps } from '../types';

export const ContentColumns: FC<MhyChakraProps> = (props) => (
  <Grid
    templateColumns={[
      'repeat(auto-fit, minmax(280px, 1fr))',
      'repeat(auto-fit, minmax(320px, 1fr))',
    ]}
    gridColumnGap="10"
    gridRowGap="3"
    alignItems="flex-start"
    width="100%"
    {...props}
  />
);

export const ContentColumn: FC<MhyChakraProps> = (props) => (
  <Grid
    gridRowGap={[5, null, 10]}
    width="100%"
    gridTemplateColumns="100%"
    {...props}
  />
);

export const MetaContent: FC<MhyChakraProps> = (props) => (
  <Flex
    direction="column"
    bg="gray1"
    borderRadius="lg"
    gridRowGap={5}
    p={[5, null, 10]}
    {...props}
  />
);

export const SectionHeader: FC<
  MhyChakraProps & { title?: string; titleAs?: As }
> = ({ title, titleAs, children, ...rest }) => (
  <Flex as="header" justifyContent="space-between" mb={8} {...rest}>
    {title && (
      <Heading as={titleAs || 'h2'} variant="h5">
        {title}
      </Heading>
    )}
    {children}
  </Flex>
);
