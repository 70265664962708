import dynamic from 'next/dynamic';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import EventTeaser from '../EventTeaser';
import { EventTeasers as Props, MhyChakraProps } from '../../types';
import ArticleGridWrapper from '../ArticleGridWrapper';
import Carousel from '../Carousel/Carousel';
import { SectionHeader } from '../BasicBlocks';

const ArrowLink = dynamic(() => import('../ArrowLink'));

const EventTeasers = ({
  data: events,
  all_link: link,
  title,
  ...rest
}: Props & Omit<MhyChakraProps, 'variant'>) => {
  const isMobile = useBreakpointValue({ md: true });

  if (events.length === 0) {
    return null;
  }

  return (
    <Box
      as="section"
      gridColumn={['1 / -1', null, '2']}
      {...rest}
      data-test-id="section-event-teasers"
    >
      <SectionHeader title={title} pl={[5, 5, 0]}>
        {link && <ArrowLink href={link.url}>{link.title}</ArrowLink>}
      </SectionHeader>
      {events.length < 5 || !isMobile ? (
        <ArticleGridWrapper colCount="4" itemMinWidth="225px" isOrderedContent>
          {events.map((event) => (
            <EventTeaser key={`${event.id}-${event.site}`} {...event} as="li" />
          ))}
        </ArticleGridWrapper>
      ) : (
        <Carousel carouselType="event">
          {events.map((event) => (
            <EventTeaser key={`${event.id}-${event.site}`} {...event} />
          ))}
        </Carousel>
      )}
    </Box>
  );
};

export default EventTeasers;
